import { type LeadFilter } from '~/store/prismic/state'
import { calculateAge } from '~/utils/date'

const CHARGED_SOURCE_INCLUDED_PREFIXES = [
  'ck_',
  'dy_',
  'sl_',
  'ps_',
  'sem_',
  'email_',
]
const CHARGED_SOURCE_EXCLUDED_PREFIXES = ['email_pe_']

export const isValidRankAges = (
  birthDate: string | undefined,
  cmpid: string | undefined,
  leadFilters: LeadFilter[]
) => {
  if (birthDate === undefined) return false

  const age = calculateAge(birthDate)
  const source = leadSource(cmpid)
  let isInRange = false

  try {
    const ageRanges = leadFilters || []

    for (const key in ageRanges) {
      const {
        age_min: minAge,
        age_max: maxAge,
        source_payant: isCharged,
        source_naturel: isOrganic,
      } = ageRanges[key]

      if (age >= minAge && age < maxAge) {
        isInRange =
          (isCharged && source === 'charged') ||
          (isOrganic && source === 'organic') ||
          isInRange
      }
    }
  } catch (e) {}

  return isInRange
}

const leadSource = (cmpid: string | undefined) => {
  if (
    cmpid &&
    CHARGED_SOURCE_INCLUDED_PREFIXES.some((prefix) =>
      cmpid.startsWith(prefix)
    ) &&
    !CHARGED_SOURCE_EXCLUDED_PREFIXES.some((prefix) => cmpid.startsWith(prefix))
  ) {
    return 'charged'
  }

  return 'organic'
}
