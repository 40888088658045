import { render, staticRenderFns } from "./contact.vue?vue&type=template&id=00fabcfc"
import script from "./contact.vue?vue&type=script&setup=true&lang=ts"
export * from "./contact.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./contact.vue?vue&type=style&index=0&id=00fabcfc&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsInputRadioCard: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/input-radio-card/input-radio-card.vue').default,AtomsInputBase: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/input-base/input-base.vue').default,AtomsLisaAutocomplete: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/lisa-autocomplete/lisa-autocomplete.vue').default,VuelidateErrors: require('/vercel/path0/components/VuelidateErrors.vue').default,AtomsSwitch: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/switch/switch.vue').default,Step: require('/vercel/path0/components/Step.vue').default,OrganismsHighlightV2: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/highlight-v2/highlight-v2.vue').default})
